<template>
  <button tabindex="-1" type="button" class="btn btn-outline-danger" @click="clicked">
    <i class="icon-reload-alt"></i>
    {{title}}
  </button>
</template>

<script>
export default {
  name: 'ResetButton',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods:{
    clicked() {
      const self = this;
      self.$emit('handle_reset_action');
    },
  }
}
</script>

<style scoped>

</style>
