<template>
  <div class="card" id="nonbook_card">

    <div style="background-color: whitesmoke;">

      <form class="container p-1">

        <div class="form-group row" >
          <label for="txtCode"  class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Serial Code</label>
          <div class="col-md-2 col-sm-4">
            <input ref="txtCode" type="text" class="form-control" id="txtCode" maxlength="10" autocomplete="off" v-if="nonbook" v-model="nonbook.code">
          </div>

          <div col-md-3 style="background-color: #4ca950;">
            <button type="button" class="btn btn-secondary btn-icon"><i class="icon-search4"></i></button>
          </div>
        </div>

        <!--Book Name & Titile-->
        <div class="form-group row" >
          <label for="txtName" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Title</label>
          <div class="col-md-5 col-sm-10">
            <input type="text" class="form-control" id="txtName" maxlength="100" autocomplete="off" v-if="nonbook" v-model="nonbook.name">
          </div>

          <label for="txt_Title" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Sub Title</label>
          <div class="col-md-5 col-sm-10">
            <input type="text" class="form-control" id="txt_Title" maxlength="100" autocomplete="off" v-if="nonbook" v-model="nonbook.title">
          </div>

        </div>

        <!--Publisher-->
        <div class="form-group row" >
          <label for="txtPublisher" class="col-md-1 col-form-label font-weight-semibold">Publisher</label>
          <div class="col-md-5">
            <select type="text" class="form-control" id="txtPublisher" v-if="nonbook" v-model="nonbook.publisher_id">
              <option v-for="pub in publishers" v-bind:value="pub.id">
                {{ pub.name }}
              </option>
            </select>
          </div>


          <label for="txtPrice" class="col-md-1 col-form-label font-weight-semibold">Price</label>
          <div class="col-md-2">
            <input ref="txtPrice" class="form-control  text-right" id="txtPrice" min="0" v-if="nonbook" v-model="nonbook.price">
          </div>

          <label class="col-md-1 col-form-label font-weight-semibold" data-popup="popover" title="Language" data-trigger="hover" data-content="Select the Content Language">Lang</label>
          <div class="col-md-2">
            <select class="form-control" id="txtLanguage" v-if="nonbook" v-model="nonbook.language" data-popup="popover" title="Language" data-trigger="hover" data-content="Select the Content Language">
              <option value="en" selected>English</option>
              <option value="ta">Tamil</option>
              <option value="ma">Malayam</option>
              <option value="hi">Hindi</option>
            </select>
          </div>
        </div>


        <!--Department Pages Location-->
        <div class="form-group row" >

          <label for="txtDepartment" class="col-md-1 col-form-label font-weight-semibold">Department</label>
          <div class="col-md-5">
            <select class="form-control" v-if="nonbook" v-model="nonbook.department_id">
              <option v-for="pub in departments" v-bind:value="pub.id">
                {{ pub.name }}
              </option>
            </select>
          </div>


          <label for="txtSubject" class="col-md-1 col-form-label font-weight-semibold">Vendor</label>
          <div class="col-md-5">
            <select class="form-control" v-if="nonbook" v-model="nonbook.vendor_id">
              <option v-for="pub in vendors" v-bind:value="pub.id">
                {{ pub.name }}
              </option>
            </select>
          </div>
        </div>


        <div class="row float-right pr-2 pb-1" >
          <ResetButton @handle_reset_action="perform_clear_action"></ResetButton>
          <SaveButton title="Add Non-Book" @handle_save_action="perform_save_action"/>
        </div>


      </form>


    </div>

  </div>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '@/components/core/buttons/SaveButton'
import Cleave from 'cleave.js'
import ResetButton from '@/components/core/buttons/ResetButton'

export default {
  name: 'NonBookCard',
  components: {
    ResetButton,
    SaveButton
  },
  data () {
    return {
      current_access_no: 0,
      nonbook: JSON.parse('{"id":0,"status":"Available","type":"NonBook","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}'),
      publishers:[],
      authors:[],
      categories: [],
      subjects:[],
      vendors:[],
      libraries:[],
      departments: [],
    }
  },
  store,
  props: {
    mypublishers:{
      type:Array,
      default: []
    },
    mysubjects:{
      type:Array,
      default: []
    },
    mysections:{
      type:Array,
      default: []
    },
    mydepartments:{
      type:Array,
      default: []
    },
    myvendors:{
      type:Array,
      default: []
    },
    myauthors:{
      type:Array,
      default: []
    },
    mycategories:{
      type:Array,
      default: []
    },
    mylibraries:{
      type:Array,
      default: []
    },
    mybook: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Available","type":"NonBook","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}')
    }
  },
  beforeMount () {
    this.nonbook = this.mybook;
  },
  created () {
    this.$data.nonbook = JSON.parse('{"id":0,"status":"Available","type":"NonBook","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}');
    this.$data.nonbook.year = (new Date()).getFullYear();
  },
  mounted () {
    const self = this;

    try {

      this.$data.nonbook.year = (new Date()).getFullYear();
      self.loadPublishers();
      self.loadAuthors();
      self.loadDepartments();
      self.loadVendors();

      // self.loadVendors();

      // if (self.$props.mypublishers.length == 0) {
      //   self.loadPublishers();
      // } else {
      //   self.$data.publishers = self.$props.mypublishers;
      // }
      //
      // if (self.$props.mycategories.length == 0) {
      //   self.loadCategories();
      // } else {
      //   self.$data.categories = self.$props.mycategories;
      // }
      // if (self.$props.myauthors.length == 0) {
      //   self.loadAuthors();
      // } else {
      //   self.$data.authors = self.$props.myauthors;
      // }

      // if (self.$props.mydepartments.length == 0) {
      //   self.loadDepartments();
      // } else {
      //   self.$data.departments = self.$props.mydepartments;
      // }

      // if (self.$props.mysubjects.length == 0) {
      //   self.loadSubjects();
      // } else {
      //   self.$data.subjects = self.$props.mysubjects;
      // }

      //txtPrice
      new Cleave(self.$refs.txtPrice, {
        numeral: true,
        numeralThousandsGroupStyle: 'lakh'
      });
      //txtISBN
      // new Cleave(self.$refs.txtISBN, {
      //   delimiter: ' ',
      //   blocks: [3, 2, 1, 6],
      // });

      $('#txtCode').focus();

    }catch (e) {
      alert(e + "\n" + " in nonbookcard");
    }

  },
  methods: {
    closeThis () {
      this.$emit('nonbook_window_closed');
      if(this.nonbook.id > 0 ) {
        this.$router.push("/")
      }
    },
    setTitle () {
      return this.nonbook.id == 0 ? 'Non-Book Creation' : 'Non-Book Updation'
    },
    loadData(){
      try {
        this.loadCategories();
        this.loadVendors();
        this.loadPublishers();
        this.loadAuthors();
      }catch (e) {
        alert(e);
      }
    },
    loadPublishers(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#nonbook_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.publishers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/publishers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.publishers = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#nonbook_card').unblock();
      });
    },
    loadVendors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#nonbook_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.vendors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/vendors`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.vendors = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#nonbook_card').unblock();
      });
    },
    get_current_access_no(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#nonbook_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });
      self.$data.current_access_no = "";
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/books/current_accessno`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.current_access_no = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#nonbook_card').unblock();
      });
    },
    loadAuthors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#nonbook_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.authors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/authors`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.authors = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#nonbook_card').unblock();
      });
    },
    loadDepartments(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#nonbook_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.departments = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.departments = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#nonbook_card').unblock();
      });
    },
    loadCategories(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#nonbook_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.categories = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/categories`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.categories = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#nonbook_card').unblock();
      });
    },
    loadSubjects () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#nonbook_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      this.$data.subject = JSON.parse('{"id":0,"name":"","parent_id":1}');

      self.$data.subjects = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subjects`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.subjects =  resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#nonbook_card').unblock();
      });
    },
    perform_clear_action(){
      const self = this;
      self.$data.nonbook = JSON.parse('{"id":0,"status":"Available","type":"NonBook","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}');
      self.$refs.txtCode.focus();
    },
    perform_save_action(){
      const self = this;
      try {

        self.$data.nonbook.price = parseFloat(self.$data.nonbook.price.replaceAll(',',''));

        self.$data.nonbook.authors = [];
        $("#cmbAuthor :selected").each(function () {
          let author = {"id": parseInt(this.value), "name": "" };
          self.$data.nonbook.authors.push(author);
        });

        if( self.$data.nonbook.title == null) {
          self.$data.nonbook.title = "";
        }

        if( self.$data.nonbook.code == null) {
          self.$data.nonbook.code = "";
        }

        if( self.$data.nonbook.edition == null) {
          self.$data.nonbook.edition = "";
        }

        if( self.$data.nonbook.isbn == null) {
          self.$data.nonbook.isbn = "";
        }

        if( self.$data.nonbook.rack == null) {
          self.$data.nonbook.rack = "";
        }

        self.$data.nonbook.qty = 1;

        if(self.$data.nonbook.code.trim().length == 0) {
          alert('Invalid Periodical Code');
          return;
        }else if(self.$data.nonbook.name.trim().length == 0) {
          alert('Invalid Periodical Title');
          return;
        }else if( parseInt(self.$data.nonbook.qty) < 1 ) {
          alert('Invalid Periodical Quantity');
          return;
        }else if( parseInt(self.$data.nonbook.category_id) < 1 ) {
          alert('Invalid Periodical Category');
          return;
        }else if( parseInt(self.$data.nonbook.publisher_id) < 1 ) {
          alert('Invalid Periodical Publisher');
          return;
        }else if( parseInt(self.$data.nonbook.vendor_id) < 1 ) {
          alert('Invalid Periodical Vendor');
          return;
        }

        $('#nonbook_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.nonbook.type = 'NonBook';

        const requestOptions = {
          method: (self.$data.nonbook.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.nonbook)
        }

        console.log(JSON.stringify(self.$data.nonbook));

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/nonbook`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtCode').focus()}, timer:1500 });
            self.nonbook = JSON.parse('{"id":0,"status":"Available","type":"NonBook","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}');
            self.$emit('nonbook_saved',resp.data);
            $('#txtCode').focus();
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtCode').focus()}, timer:3000 });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtCode').focus()}, timer:3000 });
        }).finally(()=>{
          $('#nonbook_card').unblock();
        });

      }catch (e) {
        alert(e);
      }

    },
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00!important;
  color: #0a0a0a!important;
}
</style>
