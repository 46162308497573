<template>
  <div class="card" id="periodical_card">


    <div style="background-color: whitesmoke;">

      <form class="container p-1">

        <div class="form-group row" >
          <label for="txtCode" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Serial Code</label>
          <div class="col-md-2 col-sm-4">
            <input type="text" class="form-control" id="txtCode" maxlength="10" autocomplete="off" v-if="periodical" v-model="periodical.code">
          </div>

          <div col-md-3 style="background-color: #4ca950;">
            <button type="button" class="btn btn-secondary btn-icon"><i class="icon-search4"></i></button>
          </div>
        </div>

        <!--Book Name & Titile-->
        <div class="form-group row" >
          <label for="txtName" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Title</label>
          <div class="col-md-5 col-sm-10">
            <input type="text" class="form-control" id="txtName" maxlength="100" autocomplete="off" v-if="periodical" v-model="periodical.name">
          </div>

          <label for="txt_Title" class="col-md-1 col-sm-2 col-form-label font-weight-semibold">Sub Title</label>
          <div class="col-md-5 col-sm-10">
            <input type="text" class="form-control" id="txt_Title" maxlength="100" autocomplete="off" v-if="periodical" v-model="periodical.title">
          </div>

        </div>

        <!--Publisher-->
        <div class="form-group row" >
          <label for="txtPublisher" class="col-md-1 col-form-label font-weight-semibold">Publisher</label>
          <div class="col-md-5">
            <select type="text" class="form-control" id="txtPublisher" v-if="periodical" v-model="periodical.publisher_id">
              <option v-for="pub in publishers" v-bind:value="pub.id">
                {{ pub.name }}
              </option>
            </select>
          </div>


          <label for="txtCity" class="col-md-1 col-form-label font-weight-semibold">City</label>
          <div class="col-md-2">
            <input ref="txtCity" class="form-control  text-right" id="txtCity" maxlength="50" autocomplete="off" v-if="periodical" v-model="periodical.city">
          </div>

          <label class="col-md-1 col-form-label font-weight-semibold" data-popup="popover" title="Language" data-trigger="hover" data-content="Select the Content Language">Lang</label>
          <div class="col-md-2">
            <select class="form-control" id="txtLanguage" v-if="periodical" v-model="periodical.language" data-popup="popover" title="Language" data-trigger="hover" data-content="Select the Content Language">
              <option value="en" selected>English</option>
              <option value="ta">Tamil</option>
              <option value="ma">Malayam</option>
              <option value="hi">Hindi</option>
            </select>
          </div>
        </div>


        <!--Department Pages Location-->
        <div class="form-group row" >

          <label for="txtDepartment" class="col-md-1 col-form-label font-weight-semibold">Department</label>
          <div class="col-md-5">
            <select class="form-control" v-if="periodical" v-model="periodical.department_id">
              <option v-for="pub in departments" v-bind:value="pub.id">
                {{ pub.name }}
              </option>
            </select>
          </div>


          <label for="txtPrice" class="col-md-1 col-form-label font-weight-semibold">Price</label>
          <div class="col-md-2">
            <input ref="txtPrice" class="form-control  text-right" id="txtPrice" min="0" v-if="periodical" v-model="periodical.price">
          </div>

          <label for="txtPages" class="col-md-1 col-form-label font-weight-semibold">Pages</label>
          <div class="col-md-2">
            <input type="number" class="form-control text-right" id="txtPages" min="0" v-if="periodical" v-model="periodical.pages">
          </div>

        </div>

        <!--Price, Pages Location-->
        <div class="form-group row" >
          <label for="txtSubject" class="col-md-1 col-form-label font-weight-semibold">Periodicity</label>
          <div class="col-md-5">
            <select class="form-control" v-if="periodical" v-model="periodical.periodicity">
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="BiWeekly">Bi-Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="BiMonthly">Bi-Monthly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="HalfYearly">Half Yearly</option>
              <option value="Yearly">Yearly</option>
            </select>
          </div>

          <label for="txtISBN" class="col-md-1 col-form-label font-weight-semibold">ISSN</label>
          <div class="col-md-2">
            <input ref="txtISBN" class="form-control" autocomplete="off" v-if="periodical" v-model="periodical.isbn">
          </div>

          <label for="txtSubscribeNo" class="col-md-1 col-form-label font-weight-semibold">Subscribe No</label>
          <div class="col-md-2">
            <input ref="txtSubscribeNo" id="txtSubscribeNo" class="form-control" autocomplete="off" v-if="periodical" v-model="periodical.subscribe_no">
          </div>

        </div>

        <div class="row float-right pr-2 pb-1" >
          <ResetButton @handle_reset_action="perform_clear_action"></ResetButton>
          <SaveButton title="Add Periodical" @handle_save_action="perform_save_action"/>
        </div>


      </form>


    </div>

  </div>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
import SaveButton from '@/components/core/buttons/SaveButton'
import Cleave from 'cleave.js'
import ResetButton from '../core/buttons/ResetButton'

export default {
  name: 'PeriodicalCard',
  components: {
    ResetButton,
    SaveButton
  },
  data () {
    return {
      current_access_no: 0,
      periodical: JSON.parse('{"id":0,"status":"Available","type":"Periodical","periodicity":"Monthly","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}'),
      publishers:[],
      authors:[],
      categories: [],
      subjects:[],
      vendors:[],
      libraries:[],
      departments: [],
    }
  },
  store,
  props: {
    mypublishers:{
      type:Array,
      default: []
    },
    mysubjects:{
      type:Array,
      default: []
    },
    mysections:{
      type:Array,
      default: []
    },
    mydepartments:{
      type:Array,
      default: []
    },
    myvendors:{
      type:Array,
      default: []
    },
    myauthors:{
      type:Array,
      default: []
    },
    mycategories:{
      type:Array,
      default: []
    },
    mylibraries:{
      type:Array,
      default: []
    },
    mybook: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"status":"Available","type":"Periodical","periodicity":"Monthly","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}')
    }
  },
  beforeMount () {
    this.periodical = this.mybook;
  },
  created () {
    this.$data.periodical = JSON.parse('{"id":0,"status":"Available","type":"Periodical","periodicity":"Monthly","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}');
    this.$data.periodical.year = (new Date()).getFullYear();
  },
  mounted () {
    const self = this;

    try {

      this.$data.periodical.year = (new Date()).getFullYear();

      self.loadVendors();

      if (self.$props.mypublishers.length == 0) {
        self.loadPublishers();
      } else {
        self.$data.publishers = self.$props.mypublishers;
      }

      if (self.$props.mycategories.length == 0) {
        self.loadCategories();
      } else {
        self.$data.categories = self.$props.mycategories;
      }

      if (self.$props.myauthors.length == 0) {
        // self.loadAuthors();
      } else {
        self.$data.authors = self.$props.myauthors;
      }

      if (self.$props.mydepartments.length == 0) {
        self.loadDepartments();
      } else {
        self.$data.departments = self.$props.mydepartments;
      }

      if (self.$props.mysubjects.length == 0) {
        self.loadSubjects();
      } else {
        self.$data.subjects = self.$props.mysubjects;
      }

      //txtPrice
      new Cleave(self.$refs.txtPrice, {
        numeral: true,
        numeralThousandsGroupStyle: 'lakh'
      });
      //txtISBN
      new Cleave(self.$refs.txtISBN, {
        delimiter: '-',
        blocks: [4,4],
      });

      $('#txtCode').focus();

    }catch (e) {
      alert(e);
    }

  },
  methods: {
    closeThis () {
      this.$emit('periodical_window_closed');
      if(this.periodical.id > 0 ) {
        this.$router.push("/")
      }
    },
    setTitle () {
      return this.periodical.id == 0 ? 'Periodical Creation' : 'Periodical Updation'
    },
    loadData(){
      try {
        this.loadCategories();
        this.loadVendors();
        this.loadPublishers();
      }catch (e) {
        alert(e);
      }
    },
    loadPublishers(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#periodical_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.publishers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/publishers`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.publishers = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#periodical_card').unblock();
      });
    },
    loadVendors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#periodical_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.vendors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/vendors`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.vendors = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#periodical_card').unblock();
      });
    },
    get_current_access_no(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#periodical_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });
      self.$data.current_access_no = "";
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/books/current_accessno`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.current_access_no = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#periodical_card').unblock();
      });
    },
    loadDepartments(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#periodical_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.departments = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/departments`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.departments = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#periodical_card').unblock();
      });
    },
    loadCategories(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#periodical_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.categories = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/categories`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {
            self.$data.categories = resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#periodical_card').unblock();
      });
    },
    loadSubjects () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      $('#periodical_card').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      this.$data.subject = JSON.parse('{"id":0,"name":"","parent_id":1}');

      self.$data.subjects = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/lms/subjects`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (_.isArray(resp.data)) {
            self.$data.subjects =  resp.data;
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' });
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
      }).finally(()=>{
        $('#periodical_card').unblock();
      });
    },
    perform_clear_action(){
      const self = this;
      this.$data.periodical = JSON.parse('{"id":0,"status":"Available","type":"Periodical","periodicity":"Monthly","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}');
    },
    perform_save_action(){
      const self = this;
      try {

        self.$data.periodical.price = parseFloat(self.$data.periodical.price.replaceAll(',',''));

        self.$data.periodical.authors = [];
        $("#cmbVendor :selected").each(function () {
          let author = {"id": parseInt(this.value), "name": "" };
          self.$data.periodical.authors.push(author);
        });

        if( self.$data.periodical.title == null) {
          self.$data.periodical.title = "";
        }

        if( self.$data.periodical.code == null) {
          self.$data.periodical.code = "";
        }

        if( self.$data.periodical.edition == null) {
          self.$data.periodical.edition = "";
        }

        if( self.$data.periodical.isbn == null) {
          self.$data.periodical.isbn = "";
        }

        if( self.$data.periodical.rack == null) {
          self.$data.periodical.rack = "";
        }

        self.$data.periodical.qty = 1;

        if(self.$data.periodical.code.trim().length == 0) {
          alert('Invalid Periodical Code');
          return;
        }else if(self.$data.periodical.name.trim().length == 0) {
          alert('Invalid Periodical Title');
          return;
        }else if( parseInt(self.$data.periodical.qty) < 1 ) {
          alert('Invalid Periodical Quantity');
          return;
        }else if( parseInt(self.$data.periodical.category_id) < 1 ) {
          alert('Invalid Periodical Category');
          return;
        }else if( parseInt(self.$data.periodical.publisher_id) < 1 ) {
          alert('Invalid Periodical Publisher');
          return;
        }else if( parseInt(self.$data.periodical.vendor_id) < 1 ) {
          alert('Invalid Periodical Vendor');
          return;
        }

        $('#periodical_card').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        self.$data.periodical.type = 'BackVolume';

        const requestOptions = {
          method: (self.$data.periodical.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.periodical)
        }

        console.log(JSON.stringify(self.$data.periodical));

        fetch(`${process.env.VUE_APP_ROOT_API}api/lms/periodical`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtCode').focus()}, timer:1500 });
            self.periodical = JSON.parse('{"id":0,"status":"Available","type":"Periodical","periodicity":"Monthly","isbn":"","code":"","name":"","title":"","qty":1,"language":"en","edition":"","edition_year":"","publish_year":0,"group_id":1,"rack":"","author_name":"","price":0,"pages":0,"notes":"","currency":"INR","conv_rate":1,"call_no":"","acquisition_date":"","bill_date":"","bill_no":"","authors":[],"publisher_id":1,"publisher":{"id":1,"status":"Active","name":""},"category_id":1,"category":{"id":1,"status":"Active","name":""},"department_id":1,"department":{"id":1,"status":"Active","name":""},"vendor_id":1,"vendor":{"id":1,"status":"Active","name":""},"library_id":1,"subject_id":1,"section_id":1,"subscribe_no":"","city":""}');
            self.$emit('periodical_saved',resp.data);
            $('#txtCode').focus();
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' , onClose: () => { $('#txtCode').focus()}, timer:3000 });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' , onClose: () => { $('#txtCode').focus()}, timer:3000 });
        }).finally(()=>{
          $('#periodical_card').unblock();
        });

      }catch (e) {
        alert(e);
      }

    },
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;
}

textarea:focus {
  background: #feff00;
}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00!important;
  color: #0a0a0a!important;
}
</style>
